import {Action, Selector, State, StateContext} from '@ngxs/store';
import {SettingsAction, VolumeLevel} from './settings.actions';
import {inject, Injectable} from '@angular/core';
import {LoggerService} from '../../logger.service';

export interface SettingsStateModel {
  musicVolume: VolumeLevel;
}

@State<SettingsStateModel>({
  name: 'settings',
  defaults: {
    musicVolume: VolumeLevel.High,
  }
})
@Injectable()
export class SettingsState {
  private readonly logger = inject(LoggerService).create('Settings');

  @Selector()
  public static getState(state: SettingsStateModel) {
    return state;
  }

  @Selector()
  public static musicVolume(state: SettingsStateModel) {
    return state.musicVolume;
  }

  @Action(SettingsAction.ChangeMusicVolume)
  public changeVolume(ctx: StateContext<SettingsStateModel>, {level}: SettingsAction.ChangeMusicVolume) {
    ctx.patchState({musicVolume: level});
    this.logger.logInfo('Music volume changed', {level});
  }
}
