export enum VolumeLevel {
  Muted = 0,
  Low = 30,
  Medium = 60,
  High = 90,
}

export namespace SettingsAction {
  export class ChangeMusicVolume {
    public static readonly type = '[Settings] Change Music Volume';

    constructor(public level: VolumeLevel) {
    }
  }
}
